import "./ProjectsComponent.css";
export default function ProjectsComponent() {
  return (
    <>
      <div
        className="top-section-projects-page container"
        style={{ marginBottom: "90px" }}
      >
        <div className="title-sec-top-section-projects-page">
          <div className="line-projects-page"></div>
          <p className="title-top-projects-page">مشاريعنا</p>
          <div className="line-projects-page"></div>
        </div>
        <div className="bottom-section-projects-page container">
          <h1 className="head-text-section-2 heading-top-section-projects-page">
            رؤية مستقبلية
            <span className="your-choose"> تنفيذ استثنائي </span>
          </h1>
          <p className="desc-section-t desc-top-section-projects-page">
            انطلق في رحلة مليئة بالاكتشافات مع مشاريعنا العقارية المتطورة، حيث
            نلتزم بجودة فائقة في كل جانب، من الأساس إلى أدق التشطيبات
          </p>
        </div>
      </div>
    </>
  );
}
