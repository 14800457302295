import "./Section2Sale.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartSimple,
  faChartLine,
  faPeopleGroup,
  faSignHanging,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export default function Section2Sale() {
  return (
    <>
      <div className="mainnnn">
        <div className="container-section2-sale-page container">
          <img
            src={require("../../assats/carousal1.png")}
            alt=""
            className="bac-top-section2"
          />
          <div className="container2-section2-sale-page">
            <h1 className="head-text-section-2 heading-container2-section2-sale-page">
              طريقك لبيع
              <span className="your-choose"> ميسّر وسريع </span>
            </h1>
            <p className="desc-section-t">
              في أبعاد العقارية نهتم بكل جانب في عملية بيع بيتك، ونسعى لجعلها
              سهلة وممتعة من خلال استراتيجيات مبتكرة ودعم متواصل
            </p>
          </div>
          <div className="bottom-container2-section2-sale-page">
            <div className="item-container-section-5 item-container2 item-container-section-5-s">
              <FontAwesomeIcon
                icon={faChartLine}
                className="icon-item-section-5 title-item-section-5-s"
              />
              <h1 className="title-item-section-5 title-item-section-5-s ali">
                الالتزام بالجودة والشفافية
              </h1>
              <p className="desc-item-section-5 title-item-section-5-s">
                نحن نلتزم بتقديم أفضل الخدمات وبأعلى درجات الشفافية لتلبية
                احتياجاتك
              </p>
            </div>
            <div className="item-container-section-5 item-container2">
              <FontAwesomeIcon
                icon={faChartSimple}
                className="icon-item-section-5 "
              />

              <h1 className="title-item-section-5 ali">تسويق مبتكر </h1>
              <p className="desc-item-section-5">
                حلولنا التسويقية المبتكرة والمؤثرة تضمن زيادة قيمة عقارك وجذب
                العملاء
              </p>
            </div>
            <div className="item-container-section-5 item-container2">
              <FontAwesomeIcon
                icon={faPeopleGroup}
                className="icon-item-section-5 "
              />

              <h1 className="title-item-section-5 ali">فريق متخصص ومحترف </h1>
              <p className="desc-item-section-5">
              مع فريق أبعاد العقارية، ستحظى بتجربة تسويق عقاري مميزة تسعى لتحقيق النتائج المثلى

              </p>
            </div>
            <div className="item-container-section-5 item-container2">
              <FontAwesomeIcon
                icon={faSignHanging}
                className="icon-item-section-5 "
              />
              <h1 className="title-item-section-5 ali">متخصصون في العقارات</h1>
              <p className="desc-item-section-5">
              نقدم خدماتنا المتخصصة في التسويق العقاري مستفيدين من خبرتنا الواسعة في هذا المجال

              </p>
            </div>
          </div>
          <div className="container-button container-button-sale-page">
            <Link to={"/contactus"}>
              <button className="btns-section-2">بيع معنا الآن</button>
            </Link>
            <Link to={"/about"}>
              <button className="btns-section-2 btn-read-more">
                اعرف أكثر
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
