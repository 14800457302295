import { NavLink } from "react-router-dom";
import "./PaymentMethod.css";
export default function PaymentMethod() {
  return (
    <>
      <div className="payment-method-section">
        <div className="payment-method-container container">
          <div className="left-about-us">
            <h1 className="head-text-section-2">
              <span className="our-info-text"> أبدا بالبيع معنا</span>
              <br />
              كيف تبيع
              <span className="your-choose"> معنا ؟ </span>
            </h1>
            <p className="desc-section-t">
              مع تحليلات سوق شاملة وإتمام صفقات ناجحة، فريقنا يسعى ليكون مفتاح
              نجاح بيع منزلك. اطمئن، فأنت في أيدٍ ماهرة من خبراء العقارات
            </p>
            <div className="container-button">
              <NavLink to={"/projects"}>
                <button className="btns-section-2">شوف مشاريعنا</button>
              </NavLink>
              <NavLink to={"/about"}>
                <button className="btns-section-2 btn-read-more">
                  اعرف أكثر
                </button>
              </NavLink>
            </div>
          </div>
          <div className="payment-method-left">
            <div className="payment-method">
              <div className="number-container">1</div>
              <div className="container-text-payment">
                <h1 className="heading-container-text-payment"> أضف عقارك </h1>
                <p className="desc-container-text-payment">
                واحصل على استشارة مجانية من خبرائنا العقاريين
                </p>
              </div>
            </div>
            <div className="payment-method">
              <div className="number-container">2</div>
              <div className="container-text-payment">
                <h1 className="heading-container-text-payment">
                دع الباقي على فريقنا
                </h1>
                <p className="desc-container-text-payment">
                فريق أبعاد العقارية سيهتم بكل تفاصيل رحلة البيع حتى إتمامها                </p>
              </div>
            </div>
            <div className="payment-method">
              <div className="number-container">3</div>
              <div className="container-text-payment">
                <h1 className="heading-container-text-payment">تم البيع </h1>
                <p className="desc-container-text-payment">
                تمت الصفقة بسهولة ويسر
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
