import "./LastSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshake,
  faChartLine,
  faUserGroup,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";

import EndSection from "../endSection/EndSection";
import { NavLink } from "react-router-dom";
export default function LastSection() {
  return (
    <>
      <div className="sectionn-5">
        <div className="container-cintent-section-5 container">
          <div className="right-items-section-5">
            <div className="item-container-section-5 ">
              <FontAwesomeIcon
                icon={faChartLine}
                className="icon-item-section-5 "
              />
              <h1 className="title-item-section-5">
                الالتزام بالجودة والشفافية
              </h1>
              <p className="desc-item-section-5">
                يضمن لك تجربة متميزة ويجعلنا خيارك الأفضل
              </p>
            </div>
            <div className="item-container-section-5 item-container-section-5-s">
              <FontAwesomeIcon
                icon={faHandshake}
                className="icon-item-section-5 icon-item-section-5-s"
              />
              <h1 className="title-item-section-5 title-item-section-5-s">
                تسويق مبتكر وفعّال{" "}
              </h1>
              <p className="desc-item-section-5 title-item-section-5-s">
                ترفع قيمة عقارك وتجذب العديد من العملاء{" "}
              </p>
            </div>
            <div className="item-container-section-5">
              <FontAwesomeIcon icon={faHouse} className="icon-item-section-5" />
              <h1 className="title-item-section-5">متخصصون في السوق العقاري</h1>
              <p className="desc-item-section-5">
                يجعلنا نقدم لكم خدمات متميزة بفضل خبرتنا الواسعة
              </p>
            </div>
            <div className="item-container-section-5">
              <FontAwesomeIcon
                icon={faUserGroup}
                className="icon-item-section-5"
              />
              <h1 className="title-item-section-5"> فريق محترف ومتخصص</h1>
              <p className="desc-item-section-5">
                يسعى دائماً لتقديم تجربة تسويق عقاري مميزة تحقق أفضل النتائج
              </p>
            </div>
          </div>
          <div className="left-about-us">
            <h1 className="head-text-section-2">
              <span className="our-info-text">
                {" "}
                ------- لماذا أبعاد العقارية ؟{" "}
              </span>{" "}
              <br />
              خبرة طويلة
              <span className="your-choose">ورؤية مبتكرة</span>
            </h1>
            <p className="desc-section-t">
              نمزج بين خبرتنا الطويلة ورؤيتنا المبتكرة لنقدم لك تجارب عقارية
              مميزة بفضل سنوات من العمل , نستطيع ان نحقق اهدافك العقارية بأفضل
              طريقة ممكنة
            </p>
            <div className="container-button">
              <NavLink to={"/projects"}>
                <button className="btns-section-2"> تصفح مشاريعنا </button>
              </NavLink>
              <NavLink to={"/about"}>
                <button className="btns-section-2 btn-read-more">
                  اعرف أكثر
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <EndSection
        headerText={
          "اكتشف ما نقدمه من خدمات وحوّل ملكيتك إلى صفقة ناجحة. لا تكتفِ ببيع عقارك، ابحث عن الشريك المثالي لتحقيق أقصى استفادة"
        }
        lineTow={"لا يفوتك فرص الاستثمار الناجح"}
        desc={
          "اكتشف ما نقدمه من خدمات وحوّل ملكيتك إلى صفقة ناجحة. لا تكتفِ ببيع عقارك، ابحث عن الشريك المثالي لتحقيق أقصى استفادة"
        }
      />
    </>
  );
}
