import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation , EffectFade} from 'swiper/modules';
import 'swiper/css/effect-fade';
import "./SliderFilter.css";
import video1 from "../../assats/V1.mp4";
import video2 from "../../assats/V2.mp4";
import video3 from "../../assats/V3.mp4";
import video4 from "../../assats/V4.mp4";
import video5 from "../../assats/V5.mp4";
import video6 from "../../assats/V6.mp4";
import video7 from "../../assats/V7.mp4";
import video8 from "../../assats/V8.mp4";
import video9 from "../../assats/V9.mp4";
import video10 from "../../assats/V10.mp4";
import video11 from "../../assats/V11.mp4";


export default function SliderFilter() {
  return (
    <>
<Swiper
        spaceBetween={30}
        centeredSlides={true}
        effect={'fade'}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation, EffectFade]}
        className="mySwiper"
      >
        <SwiperSlide className='slide1'> <div className="carousel-item active container-video-carousal">
            <video src={video1} autoPlay muted loop />
            <div className="filter-carousal-video">
              <div className="container-simple">
                <h1 className="aaaaaaa">
                أبعـــاد العقارية  <span className="name-company-color">
                    شريكك
                  </span>
                </h1>
              </div>
              <div className="container-simple2">
                <h1 className="aaaaaaa2">
                  في رحلة اكتشاف منزل احلامك 
                </h1>
              </div>
            </div>
          </div></SwiperSlide>
        <SwiperSlide  className='slide1'>  <div className="carousel-item active container-video-carousal">
            <video src={video2} autoPlay muted loop />
            <div className="filter-carousal-video">
              <div className="container-simple">
                <h1 className="aaaaaaa">
                أبعـــاد العقارية  <span className="name-company-color">
                    شريكك
                  </span>
                </h1>
              </div>
              <div className="container-simple2">
                <h1 className="aaaaaaa2">
                  في رحلة اكتشاف منزل احلامك 
                </h1>
              </div>
            </div>
          </div></SwiperSlide>
        <SwiperSlide  className='slide1'> <div className="carousel-item active container-video-carousal">
            <video src={video3} autoPlay muted loop />
            <div className="filter-carousal-video">
              <div className="container-simple">
                <h1 className="aaaaaaa">
                أبعـــاد العقارية  <span className="name-company-color">
                    شريكك
                  </span>
                </h1>
              </div>
              <div className="container-simple2">
                <h1 className="aaaaaaa2">
                  في رحلة اكتشاف منزل احلامك 
                </h1>
              </div>
            </div>
          </div></SwiperSlide>
        <SwiperSlide  className='slide1'><div className="carousel-item active container-video-carousal">
            <video src={video4} autoPlay muted loop />
            <div className="filter-carousal-video">
              <div className="container-simple">
                <h1 className="aaaaaaa">
                أبعـــاد العقارية  <span className="name-company-color">
                    شريكك
                  </span>
                </h1>
              </div>
              <div className="container-simple2">
                <h1 className="aaaaaaa2">
                  في رحلة اكتشاف منزل احلامك 
                </h1>
              </div>
            </div>
          </div></SwiperSlide>
        <SwiperSlide  className='slide1'> <div className="carousel-item active container-video-carousal">
            <video src={video5} autoPlay muted loop />
            <div className="filter-carousal-video">
              <div className="container-simple">
                <h1 className="aaaaaaa">
                أبعـــاد العقارية  <span className="name-company-color">
                    شريكك
                  </span>
                </h1>
              </div>
              <div className="container-simple2">
                <h1 className="aaaaaaa2">
                  في رحلة اكتشاف منزل احلامك 
                </h1>
              </div>
            </div>
          </div></SwiperSlide>
        <SwiperSlide  className='slide1'><div className="carousel-item active container-video-carousal">
            <video src={video6} autoPlay muted loop />
            <div className="filter-carousal-video">
              <div className="container-simple">
                <h1 className="aaaaaaa">
                أبعـــاد العقارية  <span className="name-company-color">
                    شريكك
                  </span>
                </h1>
              </div>
              <div className="container-simple2">
                <h1 className="aaaaaaa2">
                  في رحلة اكتشاف منزل احلامك 
                </h1>
              </div>
            </div>
          </div></SwiperSlide>
        <SwiperSlide  className='slide1'><div className="carousel-item active container-video-carousal">
            <video src={video7} autoPlay muted loop />
            <div className="filter-carousal-video">
              <div className="container-simple">
                <h1 className="aaaaaaa">
                أبعـــاد العقارية  <span className="name-company-color">
                    شريكك
                  </span>
                </h1>
              </div>
              <div className="container-simple2">
                <h1 className="aaaaaaa2">
                  في رحلة اكتشاف منزل احلامك 
                </h1>
              </div>
            </div>
          </div></SwiperSlide>
        <SwiperSlide  className='slide1'> <div className="carousel-item active container-video-carousal">
            <video src={video8} autoPlay muted loop />
            <div className="filter-carousal-video">
              <div className="container-simple">
                <h1 className="aaaaaaa">
                أبعـــاد العقارية  <span className="name-company-color">
                    شريكك
                  </span>
                </h1>
              </div>
              <div className="container-simple2">
                <h1 className="aaaaaaa2">
                  في رحلة اكتشاف منزل احلامك 
                </h1>
              </div>
            </div>
          </div></SwiperSlide>
        <SwiperSlide  className='slide1'>  <div className="carousel-item active container-video-carousal">
            <video src={video9} autoPlay muted loop />
            <div className="filter-carousal-video">
              <div className="container-simple">
                <h1 className="aaaaaaa">
                أبعـــاد العقارية  <span className="name-company-color">
                    شريكك
                  </span>
                </h1>
              </div>
              <div className="container-simple2">
                <h1 className="aaaaaaa2">
                  في رحلة اكتشاف منزل احلامك 
                </h1>
              </div>
            </div>
          </div></SwiperSlide>
        <SwiperSlide  className='slide1'><div className="carousel-item active container-video-carousal">
            <video src={video10} autoPlay muted loop />
            <div className="filter-carousal-video">
              <div className="container-simple">
                <h1 className="aaaaaaa">
                أبعـــاد العقارية  <span className="name-company-color">
                    شريكك
                  </span>
                </h1>
              </div>
              <div className="container-simple2">
                <h1 className="aaaaaaa2">
                  في رحلة اكتشاف منزل احلامك 
                </h1>
              </div>
            </div>
          </div></SwiperSlide>
        <SwiperSlide  className='slide1'>   <div className="carousel-item active container-video-carousal">
            <video src={video11} autoPlay muted loop />
            <div className="filter-carousal-video">
              <div className="container-simple">
                <h1 className="aaaaaaa">
                أبعـــاد العقارية  <span className="name-company-color">
                    شريكك
                  </span>
                </h1>
              </div>
              <div className="container-simple2">
                <h1 className="aaaaaaa2">
                  في رحلة اكتشاف منزل احلامك 
                </h1>
              </div>
            </div>
          </div></SwiperSlide>

      </Swiper>
  
    </>
  );
}
