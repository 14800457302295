import { Link } from "react-router-dom";
import "./VideoSection.css";
export default function VideoSection() {
  return (
    <>
      <div className="main-container-video-section">
        <div className="container container-videoTest">
          <iframe
            className="video"

            src="https://www.youtube.com/embed/eWq-x4IpLRM"
            title="BEAUTIFUL MODERN 4 BEDROOM BUNGALOW HOUSE DESIGN"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>

          <div className="container-video-text">
            <h1 className="head-text-video">ما الذي يجعل أبعاد العقارية الخيار الأفضل لدى أصحاب العقارات عند البيع؟
   
            </h1>
            <p className="desc-text-video">استفد من إمكانيات عقارك القصوى لتحقيق أفضل قيمة واختر البيع بثقة لتحقيق أهدافك
            </p>
            <div className="container-item-text-video">
              <p className="desc-text-video desc-text-video-item"> إرشادات الخبراء _</p>
              <p className="desc-text-video desc-text-video-item"> تحقيق أهداف البيع  _</p>
              <p className="desc-text-video desc-text-video-item"> بيع ميسر _</p>
              <p className="desc-text-video desc-text-video-item"> استغلال ذكي للبيع _</p>
            </div>
            <Link to={'/contactus'}>
              <button className="btns-section-2">بيع معنا الآن</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
