// import { useState } from "react"
// import "./FormContactUs.css"
// import emailjs from '@emailjs/browser';
// import React, { useRef } from 'react';
// export default function FormContactUs(props) {

//   let stylingFontForm = {
//     color: "white"
//   }
//   let styleInputs = {
// border: "1px solid white",
// backgroundColor: "transparent",
// color: "white"
//   }
//   let stylebutton = {
//     backgroundColor : "white",
//     color: "#0756A5",
//   }

// const [name , setName] = useState('');
// const [city , setCity] = useState('');
// const [unit , setUnit] = useState('');
// const [email , setEmail] = useState('');
// const [phone , setPhone] = useState('');
// const [message , setMessage] = useState('');

// const handleSubmit = (e) => {
//   e.preventDefault();

// const serviceId = 'service_y8udls9' ;
// const templateId = 'template_79h13av' ;
// const publicKey = 'vp_6NcdmUVUdLs4GH' ;
// const templateParams = {
//  from_name : name,
//  from_email : email,
//  to_name : "ali",
//  message : message,
// }
// emailjs.sendForm(serviceId , templateId  , templateParams , publicKey)
// .then((response) => {
//   console.log('email send success' , response);
//   setName: ('');
//   setEmail: ('');
//   setMessage: ('');
// })
// .catch((error) => {
//   console.error('error sending email: ' , error);
// })
// };

//     return(
//         <>

// <form action="" onSubmit={handleSubmit} className="form-contactus">
//       <div className="container-inputs">
//         <div className="container-input">
//           <label htmlFor="name" className="form-label" style={props.stylingFontForm && stylingFontForm}>
//             الاسم
//           </label>
//           <input type="text"
//            value={name}
//            className="form-input-contactus"
//            id="name"
//            style={props.styleInputs && styleInputs}/>
//         </div>
//         <div className="container-input-left">
//           <div className="container-lable">
//             <label htmlFor="city" className="form-label" style={props.stylingFontForm && stylingFontForm}>
//               المدينة
//             </label>
//           </div>
//           <input type="text" className="form-input-contactus" id="city" style={props.styleInputs && styleInputs}/>
//         </div>
//       </div>
//       <div className="container-input-full">
//         <label htmlFor="unit" className="form-label" style={props.stylingFontForm && stylingFontForm}>
//           الوحدة
//         </label>
//         <input type="text" className="form-input-full-contactus" id="unit" style={props.styleInputs && styleInputs}/>
//       </div>
//       <div className="container-input-full">
//         <label htmlFor="email" className="form-label" style={props.stylingFontForm && stylingFontForm}>
//           الايميل
//         </label>
//         <input type="email"
//         value={email}
//         className="form-input-full-contactus"
//         id="email"
//         style={props.styleInputs && styleInputs}/>
//       </div>
//       <div className="container-input-full">
//         <label htmlFor="phone" className="form-label" style={props.stylingFontForm && stylingFontForm}>
//           رقم الهاتف
//         </label>
//         <input type="number" className="form-input-full-contactus" id="phone" style={props.styleInputs && styleInputs}/>
//       </div>
//       <div className="container-input-full">
//         <label htmlFor="message" className="form-label" style={props.stylingFontForm && stylingFontForm}>
//           الرسالة
//         </label>
//         <textarea
//         value={message}
//           className="form-input-full-contactus-message"
//           rows="10"
//           id="message"
//           style={props.styleInputs && styleInputs}
//         ></textarea>
//       </div>
//       <button type="submit" className="btn-send-contactus" style={props.stylebutton && stylebutton}>تواصل معنا</button>
//     </form>

//                 {/* <form action="" className="form-contactus">
//           <div className="container-inputs">
//             <div className="container-input">
//               <label htmlFor="name" className="form-label" style={props.stylingFontForm && stylingFontForm}>
//                 الاسم
//               </label>
//               <input type="text" className="form-input-contactus" id="name" style={props.styleInputs && styleInputs}/>
//             </div>
//             <div className="container-input-left">
//               <div className="container-lable">
//                 <label htmlFor="city" className="form-label" style={props.stylingFontForm && stylingFontForm}>
//                   المدينة
//                 </label>
//               </div>
//               <input type="text" className="form-input-contactus" id="city" style={props.styleInputs && styleInputs}/>
//             </div>
//           </div>
//           <div className="container-input-full">
//             <label htmlFor="unit" className="form-label" style={props.stylingFontForm && stylingFontForm}>
//               الوحدة
//             </label>
//             <input type="text" className="form-input-full-contactus" id="unit" style={props.styleInputs && styleInputs}/>
//           </div>
//           <div className="container-input-full">
//             <label htmlFor="email" className="form-label" style={props.stylingFontForm && stylingFontForm}>
//               الايميل
//             </label>
//             <input type="email" className="form-input-full-contactus" id="email" style={props.styleInputs && styleInputs}/>
//           </div>
//           <div className="container-input-full">
//             <label htmlFor="phone" className="form-label" style={props.stylingFontForm && stylingFontForm}>
//               رقم الهاتف
//             </label>
//             <input type="number" className="form-input-full-contactus" id="phone" style={props.styleInputs && styleInputs}/>
//           </div>
//           <div className="container-input-full">
//             <label htmlFor="message" className="form-label" style={props.stylingFontForm && stylingFontForm}>
//               الرسالة
//             </label>
//             <textarea
//               className="form-input-full-contactus-message"
//               rows="10"
//               id="message"
//               style={props.styleInputs && styleInputs}
//             ></textarea>
//           </div>
//           <button className="btn-send-contactus" style={props.stylebutton && stylebutton}>تواصل معنا</button>
//         </form> */}

// {/* <form ref={form} onSubmit={sendEmail}>
//       <label>Name</label>
//       <input type="text" name="user_name" />
//       <label>Email</label>
//       <input type="email" name="user_email" />
//       <label>Message</label>
//       <textarea name="message" />
//       <input type="submit" value="Send" />
//     </form> */}

//         </>
//     )
// }

import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./FormContactUs.css"

export default function FormContactUs(props) {
    let stylingFontForm = {
    color: "white"
  }
  let styleInputs = {
border: "1px solid white",
backgroundColor: "transparent",
color: "white"
  }
  let stylebutton = {
    backgroundColor : "white",
    color: "#0756A5",
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_clxdfzj", "template_3qsozat", form.current, {
        publicKey: "nsK91KLw8UmigvoqE",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      {/* <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="from_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Message</label>
        <textarea name="message" />
        <input type="submit" value="Send" />
      </form> */}
      <form ref={form} onSubmit={sendEmail} className="form-contactus">
        <div className="container-inputs">
          <div className="container-input">
            <label
              htmlFor="name"
              className="form-label"
              style={props.stylingFontForm && stylingFontForm}
            >
              الاسم
            </label>
            <input
            name="from_name"
              type="text"
              className="form-input-contactus"
              id="name"
              style={props.styleInputs && styleInputs}
            />
          </div>
          <div className="container-input-left">
            <div className="container-lable">
              <label
                htmlFor="city"
                className="form-label"
                style={props.stylingFontForm && stylingFontForm}
              >
                المدينة
              </label>
            </div>
            <input
            name="city"
              type="text"
              className="form-input-contactus"
              id="city"
              style={props.styleInputs && styleInputs}
            />
          </div>
        </div>
        {/* <div className="container-input-full">
          <label
            htmlFor="unit"
            className="form-label"
            style={props.stylingFontForm && stylingFontForm}
          >
            الوحدة
          </label>
          <input
          name="unit_name"
            type="text"
            className="form-input-full-contactus"
            id="unit"
            style={props.styleInputs && styleInputs}
          />
        </div> */}
        <div className="container-input-full">
          <label
            htmlFor="email"
            className="form-label"
            style={props.stylingFontForm && stylingFontForm}
          >
            الايميل
          </label>
          <input
            type="email"
            name="user_email"
            className="form-input-full-contactus"
            id="email"
            style={props.styleInputs && styleInputs}
          />
        </div>
        <div className="container-input-full">
          <label
            htmlFor="phone"
            className="form-label"
            style={props.stylingFontForm && stylingFontForm}
          >
            رقم الهاتف
          </label>
          <input
          name="phone_number"
            type="number"
            className="form-input-full-contactus"
            id="phone"
            style={props.styleInputs && styleInputs}
          />
        </div>
        <div className="container-input-full">
          <label
            htmlFor="message"
            className="form-label"
            style={props.stylingFontForm && stylingFontForm}
          >
            الرسالة
          </label>
          <textarea
          name="message"
            className="form-input-full-contactus-message"
            rows="10"
            id="message"
            style={props.styleInputs && styleInputs}
          ></textarea>
        </div>
        <button
          type="submit"
          className="btn-send-contactus"
          style={props.stylebutton && stylebutton}
        >
          تواصل معنا
        </button>
      </form>
    </>
  );
}
